import { Component, OnInit } from '@angular/core';
import { ColumnModel } from '@syncfusion/ej2-angular-grids';

@Component({
  selector: 'app-teste-syncgrid',
  templateUrl: './teste-syncgrid.component.html',
  styleUrls: ['./teste-syncgrid.component.scss'],
})
export class TesteSyncgridComponent implements OnInit {

  constructor() { }

  data: any[] = [];
  columns: ColumnModel[] = [
    {field: 'OrderID', headerText: 'Order'},
    {field: 'CustomerID', headerText: 'Customer'},
    {field: 'ShipCity', headerText: 'Ship City'},
    {field: 'ShipName', headerText: 'Ship Name'},
  ];
  ngOnInit() {

    this.data = [
      { OrderID: 10248, CustomerID: 'VINET', ShipCity: 'Reims', ShipName: 'Vins et alcools Chevalier' },
      { OrderID: 10248, CustomerID: 'VINET', ShipCity: 'Lyon', ShipName: 'Teste agrupar 01' },
      { OrderID: 10249, CustomerID: 'TOMSP', ShipCity: 'Münster', ShipName: 'Toms Spezialitäten' },
      { OrderID: 10250, CustomerID: 'HANAR', ShipCity: 'Rio de Janeiro', ShipName: 'Hanari Carnes' },
      { OrderID: 10251, CustomerID: 'VICTE', ShipCity: 'Lyon', ShipName: 'Victuailles en stock' },
      { OrderID: 10248, CustomerID: 'VINET', ShipCity: 'Lyon', ShipName: 'Teste agrupar 02' },
      { OrderID: 10252, CustomerID: 'SUPRD', ShipCity: 'Charleroi', ShipName: 'Suprêmes délices' }
    ];
  }

}

import { SafraComponent } from './../planejamento/safra/safra.component';
import { SharedModule } from './../../theme/shared/shared.module';
import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectFazendaComponent } from './select-fazenda/select-fazenda.component';
import { BarraTarefaItensComponent } from './barra-tarefas-itens/barra-tarefas-itens.component';
import { SelectAreaComponent } from './select-area/select-area.component';
import { SelectAtividadeComponent } from './select-atividade/select-atividade.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MapaEditorComponent } from './mapa-editor/mapa-editor.component';
import { SelectFuncionarioComponent } from './select-funcionario/select-funcionario.component';
import { SelectEquipamentoComponent } from './select-equipamento/select-equipamento.component';
import { ModalFuncionarioComponent } from './modal-funcionario/modal-funcionario.component';
import { BarraTarefasComponent } from './barra-tarefas/barra-tarefas.component';
import { NgbCollapseModule, NgbDropdownModule, NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FiltroLateralComponent } from './filtro-lateral/filtro-lateral.component';
import { RastreioMovimentoComponent } from './rastreamento/rastreio-movimento.component';
import { RouterModule } from '@angular/router';
import { GraficoPizzaComponent } from './grafico-pizza/grafico-pizza.component';
import { GraficoQuantitativoComponent } from './grafico-quantitativo/grafico-quantitativo.component';
import { CardModule } from "../../theme/shared/components/card/card.module";
import { TreeViewAnaliticoComponent } from './tree-view-analitico/tree-view-analitico.component';
import { SelectArvoreComponent } from './select-arvore/select-arvore.component';
import { CodSelectComponent } from './cod-select/cod-select.component';
import { FiltroLateralNotasComponent } from './filtro-lateral-notas/filtro-lateral-notas.component';
import { SelectProdutoComponent } from './select-produto/select-produto.component';
import { SelectChecklistComponent } from './select-checklist/select-checklist.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { GridComponent } from './grid/grid.component';
import { GraficoBarraComponent } from './grafico-barra/grafico-barra.component';
import { EntradaProducaoComponent } from './entrada-producao/entrada-producao/entrada-producao.component';
import { TipoDescontoProducaoComponent } from './entrada-producao/tipo-desconto-producao/tipo-desconto-producao.component';
import { FormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { EditaMovimentoEstoqueComponent } from './edita-movimento-estoque/edita-movimento-estoque.component';
import { SelectsafraComponent } from './select-safra/select-safra.component';
import { SyncGridComponent } from './sync-grid/sync-grid.component';
import { GridModule } from '@syncfusion/ej2-angular-grids'


@NgModule({
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
  declarations: [
    SelectFazendaComponent,
    SelectAreaComponent,
    SelectAtividadeComponent,
    MapaEditorComponent,
    SelectFuncionarioComponent,
    SelectEquipamentoComponent,
    BarraTarefaItensComponent,
    ModalFuncionarioComponent,
    BarraTarefasComponent,
    FiltroLateralComponent,
    GridComponent,
    GraficoPizzaComponent,
    GraficoQuantitativoComponent,
    TreeViewAnaliticoComponent,
    SelectArvoreComponent,
    RastreioMovimentoComponent,
    CodSelectComponent,
    FiltroLateralNotasComponent,
    SelectFazendaComponent,
    SelectAreaComponent,
    SelectAtividadeComponent,
    MapaEditorComponent,
    SelectFuncionarioComponent,
    SelectEquipamentoComponent,
    ModalFuncionarioComponent,
    SelectProdutoComponent,
    SelectChecklistComponent,
    GraficoBarraComponent,
    EntradaProducaoComponent,
    TipoDescontoProducaoComponent,
    EditaMovimentoEstoqueComponent,
    SelectsafraComponent,
    SyncGridComponent
  ],
  exports: [
    SelectFazendaComponent,
    SelectAreaComponent,
    SelectAtividadeComponent,
    MapaEditorComponent,
    SelectFuncionarioComponent,
    SelectEquipamentoComponent,
    ModalFuncionarioComponent,
    BarraTarefaItensComponent,
    BarraTarefasComponent,
    FiltroLateralComponent,
    GridComponent,
    GraficoPizzaComponent,
    GraficoQuantitativoComponent,
    TreeViewAnaliticoComponent,
    SelectArvoreComponent,
    RastreioMovimentoComponent,
    CodSelectComponent,
    FiltroLateralNotasComponent,
    SelectProdutoComponent,
    SelectChecklistComponent,
    EntradaProducaoComponent,
    TipoDescontoProducaoComponent,
    SelectChecklistComponent,
    GraficoBarraComponent,
    EditaMovimentoEstoqueComponent,
    SelectsafraComponent,
    SyncGridComponent
  ],
  imports: [
    GridModule,
    TableModule,
    CommonModule,
    SharedModule,
    NgSelectModule,
    NgbDropdownModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    CardModule,
    RouterModule,
    FormsModule,
    CurrencyMaskModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
    })
  ]
})
export class SharedFarmModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { CommonModule } from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import {
  ColumnChooserService,
  ColumnMenuService,
  ColumnModel,
  EditService,
  EditSettingsModel,
  ExcelExportService,
  FilterService,
  GridLine,
  GroupService,
  GroupSettingsModel,
  IEditCell,
  PageService,
  PageSettingsModel,
  PdfExportService,
  ResizeService,
  SelectionSettingsModel,
  SortService,
  SortSettingsModel,
  ToolbarService,
  GridComponent as SyncFusionGridComponent,
  RecordClickEventArgs,
  RecordDoubleClickEventArgs,
  RowDataBoundEventArgs,
} from '@syncfusion/ej2-angular-grids'
import { addImage } from './addimage'
import { DataGridEtapa } from 'src/app/classes/auxiliar/AuxEtapa'
@Component({
  selector: 'app-sync-grid',
  providers: [
    PageService,
    SortService,
    FilterService,
    GroupService,
    ColumnChooserService,
    ToolbarService,
    ResizeService,
    ColumnMenuService,
    EditService,
    PdfExportService,
    ExcelExportService,
  ],
  templateUrl: './sync-grid.component.html',
  styleUrls: ['./sync-grid.component.scss'],
})
export class SyncGridComponent implements OnInit {
  @Input() data: any[]
  @Input() columns: ColumnModel[]
  @Input() emptyRecordsText: string
  @Input() height: string = '69vh'
  @Input() width: string = '100%'
  @Input() allowGroup: boolean = false
  @Input() showColumnMenu: boolean = false
  @Input() editSettings?: EditSettingsModel = {
    allowEditing: false,
    allowAdding: false,
    allowDeleting: false,
  }
  @Input() sortSettings?: SortSettingsModel = {}
  @Input() pageSettings?: PageSettingsModel = {
    pageSizes: [10, 20, 50],
    pageSize: 10,
    pageCount: 4,
  }
  @Input() selectionSettings: SelectionSettingsModel
  @Input() groupOptions?: GroupSettingsModel

  @Input() customSearch: boolean = false
  @Output() searchAction: EventEmitter<string> = new EventEmitter()
  @Output() onceClick: EventEmitter<any> = new EventEmitter()
  @Output() doubleClick: EventEmitter<any> = new EventEmitter()
  @Output() onDataBound: EventEmitter<any> = new EventEmitter()
  @Output() onRowDataBound: EventEmitter<any> = new EventEmitter()

  @ViewChild('grid', { static: true })
  grid: SyncFusionGridComponent
  filterOptions = { type: 'Excel' }

  stringParams?: IEditCell
  lines: GridLine = 'Horizontal'
  imgUrl = addImage
  heightRow: { [key: string]: number } = {
    small: 20,
    medium: 40,
    large: 60,
  }

  constructor() {}

  ngOnInit() {
    //this.groupOptions = { showGroupedColumn: true };
    this.stringParams = {
      params: {
        showClearButton: true,
      },
    }
  }

  toolbarAction(args: MouseEvent) {
    var selectedRecords: any = this.grid.getSelectedRecords()
    switch ((args.currentTarget as HTMLElement).id) {
      case 'columnButton':
        this.grid.openColumnChooser()
        break
    }
  }

  onSearch(target: EventTarget | any): void {
    if (this.customSearch) this.searchAction.emit(target.value)
    else this.grid.search(target.value)
  }

  recordClick(event: RecordClickEventArgs): void {
    this.onceClick.emit(event.rowData)
  }

  recordDoubleClick(event: RecordDoubleClickEventArgs): void {
    this.doubleClick.emit(event.rowData)
  }
  getSelectedRecords(): any[] {
    return this.grid.getSelectedRecords()
  }
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Atividade } from 'src/app/classes/Atividade';
import { Empresa } from 'src/app/classes/Empresa';
import { AtividadeService } from 'src/app/services/atividade.service';
import { EmpresaService } from 'src/app/services/empresa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
import Swal from 'sweetalert2';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { GrupoOperacaoService } from 'src/app/services/grupo-operacao.service';
import { CustomToastyService } from 'src/app/theme/shared/components/toasty/custom-toasty.service';
import { UtilsService } from 'src/app/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-atividade',
  templateUrl: './atividade.component.html',
  styleUrls: ['./atividade.component.scss']
})
export class AtividadeComponent implements OnInit {

  @Input() grupoOperacao: GrupoOperacao = new GrupoOperacao();
  @Input() gruposOperacao: GrupoOperacao[] = [];
  @Output() inserindoEvent: EventEmitter<boolean> = new EventEmitter();

  atividades: Atividade[];
  auxAtividades: Atividade[];
  atividade: Atividade;
  id:number;
  grupoId:number = 0;
  inserindo: boolean = false;
  carregando: boolean = false;
  alterandoPorGrupo: boolean = false;
  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;


  //traduções
  CANCELAR: string = "Cancelar";
  CONFIRMAR_DELETE: string = "Se deletar, esta ação não poderá ser desfeita!";
  ATIVIDADE_DELETADA: string = 'Atividade deletada!';
  FEITO: string = "Feito!";
  SIM: string = "Sim";
  ATIVIDADE_CADASTRADA: string = 'Atividade cadastrada!';
  ATIVIDADE_ALTERADA: string = 'Atividade alterada!';
  NOVA_ATIVIDADE: string = "Nova Atividade";
  EDITAR_ATIVIDADE: string = "Editar Atividade";

  constructor(private atividadesService: AtividadeService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private toasty: CustomToastyService,
    private utils: UtilsService,
    private grupoOperacaoService: GrupoOperacaoService,
    private translate: TranslateService
  ) {
    this.route.params.subscribe(res => {
      this.id = res.atividadeId;
      this.grupoId = res.grupoId;
    });
  }

   ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.atividade = new Atividade();
    this.alterandoPorGrupo = this.grupoOperacao.Id != null;
    if(this.inserindo)
      this.grupoId = Number.parseInt(this.route.snapshot.paramMap.get('grupoId'));

    if(!this.gruposOperacao || this.gruposOperacao.length == 0){
      this.grupoOperacaoService.getGruposOperacaoEmUso().subscribe(
        res => {
          this.gruposOperacao = res;
        },
        err => {
          this.utils.getErro(err);
        })
    }

    if (this.alterandoPorGrupo)
      this.atividade.GrupoOperacaoId = this.grupoOperacao.Id;
    else{
      this.atividade.GrupoOperacaoId = this.grupoId;
    }

    if(this.id > 0)
      this.selecionarAtividade();
    else if (this.alterandoPorGrupo)
      this.listarAtividades();
  }

  novaAtividade() {
    this.atividade = new Atividade();
    this.atividade.GrupoOperacaoId = this.grupoOperacao.Id;
    this.id = null;
    this.inserindo = true;
    this.inserindoEvent.emit(true);
  }

  editarAtividade(atividade: Atividade) {
    this.atividade = atividade;
    this.id = atividade.Id;
    this.inserindo = false;
    this.inserindoEvent.emit(true);
  }

  selecionarAtividade() {
    this.carregando = true;
    this.atividadesService.getAtividade(this.id).subscribe(res =>{
      this.atividade = res;
      this.grupoId = this.atividade.GrupoOperacaoId;
      this.carregando = false;
    },
      err =>{
        this.utils.getErro(err);
        this.carregando = false;
      })
  }

  listarAtividades(){
    this.carregando = true;
    this.atividadesService.getAtividadesPorGrupo(this.grupoOperacao.Id).subscribe(
      res => {
        this.atividades = res;
        this.auxAtividades = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarAtividades();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    this.ATIVIDADE_CADASTRADA = this.translate.instant("ATIVIDADE_CADASTRADA")
    this.ATIVIDADE_ALTERADA = this.translate.instant("ATIVIDADE_ALTERADA")
    this.FEITO = this.translate.instant("FEITO")
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;

    if(this.atividade.JuntaInsumosServicos)
      this.atividade.UtilizaInsumo = true;

    if(this.atividade.Id > 0)
      this.atividadesService.putAtividade(this.atividade.Id, this.atividade).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ATIVIDADE_ALTERADA, 'success');
          this.carregando = false;
          this.cancelar();
          if (this.alterandoPorGrupo)
            this.listarAtividades();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.atividadesService.postAtividade(this.atividade).subscribe(
        res => {
          this.toasty.show(this.FEITO, this.ATIVIDADE_CADASTRADA, 'success');
          this.carregando = false;
          this.cancelar();
          if (this.alterandoPorGrupo)
            this.listarAtividades();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(atividadeId: number, nome: string) {
    this.FEITO = this.translate.instant("FEITO")
    this.ATIVIDADE_DELETADA = this.translate.instant("ATIVIDADE_DELETADA")
    Swal.fire({
      title: 'Deletar '+ nome + '?',
      text: this.CONFIRMAR_DELETE,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
      if (willDelete.dismiss) {

      } else {
        this.carregando = true;
        this.atividadesService.deleteAtividade(atividadeId).subscribe(
          res => {
            this.toasty.show(this.FEITO, this.ATIVIDADE_DELETADA, 'success');
            this.listarAtividades();
            this.carregando = false;
          },
          err => {
            this.utils.getErro(err);
            this.carregando = false;
          });
      }
    });
  }

  filtrarAtividades(){
    this.atividades = this.auxAtividades.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
    );
  }

  cancelar(){
    if (!this.alterandoPorGrupo){
      this.router.navigate(['/cadastros/grupo-operacao/expandir/'+this.grupoId]);
    } else {
      this.inserindo = false;
      this.id = null;
      this.inserindoEvent.emit(false);
    }
  }
}

import { ItensAtividade } from 'src/app/classes/ItensAtividade'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core'
import { auxIntegracaoService } from 'src/app/services/auxIntegracaoService'
import { Subject } from 'rxjs'
import { ItensAtividadeService } from 'src/app/services/itens-atividade.service'
import { GrupoProdutoService } from 'src/app/services/grupo-produto.service'
import { StorageService } from 'src/app/services/storage.service'
import { ProdutoService } from 'src/app/services/produto.service'
import { EmpresaService } from 'src/app/services/empresa.service'
import { GrupoProduto } from 'src/app/classes/GrupoProduto'
import { Produto } from 'src/app/classes/Produto'
import { TipoInsumo } from 'src/app/classes/auxiliar/AuxEtapa'
import { Atividade } from 'src/app/classes/Atividade'
import { enumErpIntegrado } from 'src/app/classes/Empresa'
import { LocalEstoque } from 'src/app/classes/LocalEstoque'
import { LocalEstoqueService } from 'src/app/services/local-estoque.service'
import { UtilsService } from 'src/app/utils/utils.service'

@Component({
  selector: 'app-tabela-insumos',
  templateUrl: './tabela-insumos.component.html',
  styleUrls: ['./tabela-insumos.component.scss'],
})
export class TabelaInsumosComponent implements OnInit, OnChanges {
  @Input() tipoInsumo: TipoInsumo
  @Input() planejamentoId: number
  @Input() isPlantio: boolean = false
  @Input() sementeOuInsumo: boolean = false
  @Input() produtos: Produto[]
  @Input() sementes: Produto[]
  @Input() servicos: Produto[]
  @Input() atividade: Atividade
  @Input() itensAtividades: ItensAtividade[]

  @Output() removerEtapa: EventEmitter<any> = new EventEmitter()

  private _itensAtividade: ItensAtividade[]

  auxItensAtividades: ItensAtividade[] = []
  locaisEstoque: LocalEstoque[] = []
  temErpIntegrado: boolean = false
  //ng-select produto
  pagina: number = 2
  termoBusca: String = ''
  carregandoProduto: boolean = false
  ultimaPagina: boolean = false
  gruposProdutos: GrupoProduto[]
  dicionarioProdutos: any
  precisaoDose: number = 3
  public inputProduto$ = new Subject<string | null>()
  public inputSemente$ = new Subject<string | null>()

  constructor(
    private utils: UtilsService,
    private localEstoqueService: LocalEstoqueService,
    private storageService: StorageService,
    private produtoService: ProdutoService,
    private empresaService: EmpresaService,
  ) {
    this.inputProduto$.subscribe((newTerm) => {
      this.pagina = 1
      this.fetchMore(newTerm)
    })

    this.inputSemente$.subscribe((newTerm) => {
      this.pagina = 1
      this.fetchMoreSemente(newTerm)
    })

    var empresa = this.storageService.getEmpresa()
    this.temErpIntegrado = empresa.ErpIntegrado != enumErpIntegrado.Nenhum  //descomentar essa linha para buscar valor do produto do ERP

    this.localEstoqueService
      .listarPorFazenda(this.storageService.getFazenda().Id)
      .subscribe(
        (ret) => {
          this.locaisEstoque = ret
        },
        (err) => this.utils.getErro(err),
      )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['itensAtividades']) {
      this.listarItensAtividade()
    }
  }
  ngOnInit() {
    const empresa = this.storageService.getEmpresa()
    this.listarProdutos()
    this.empresaService.getEmpresa(empresa.Id).subscribe((res) => {
      this.precisaoDose = res.CasasDecimaisDose
    })
  }

  attItensAtividade(itens: ItensAtividade[]): void {
    this.itensAtividades = itens
    this.listarItensAtividade()
  }
  listarItensAtividade(): void {
    if (this.isPlantio)
      switch (this.tipoInsumo) {
        case TipoInsumo.Sementes:
          this.auxItensAtividades = this.itensAtividades.filter(
            (i) =>
              !i.AdubacaoLinha &&
              !i.PulverizacaoSulco &&
              !i.TratamentoSementes &&
              !i.IsServico,
          )
          break
        case TipoInsumo.AdubacaoLinha:
          this.auxItensAtividades = this.itensAtividades.filter(
            (i) => i.AdubacaoLinha == true,
          )
          break
        case TipoInsumo.PulverizacaoSulco:
          this.auxItensAtividades = this.itensAtividades.filter(
            (i) => i.PulverizacaoSulco == true,
          )
          break
        case TipoInsumo.TratamentoSemente:
          this.auxItensAtividades = this.itensAtividades.filter(
            (i) => i.TratamentoSementes == true,
          )
          break
        default:
          this.auxItensAtividades = this.itensAtividades
          break
      }
    else
      this.auxItensAtividades = this.itensAtividades.filter(
        (i) =>
          !i.AdubacaoLinha && !i.PulverizacaoSulco && !i.TratamentoSementes,
      )

    this.ordenarItens()
  }

  ordenarItens(): void {
    this.auxItensAtividades.sort((a, b) => a.Ordem - b.Ordem)
  }
  listarProdutos(): void {
    this.produtoService
      .getProdutosPaginandoSemCultivar(this.planejamentoId, 1, '', false, false)
      .subscribe((ret) => {
        this.produtos = ret.Lista
        this.montaDicionarioProdutos()
      })
  }

  changeProduto(produtoid, item: ItensAtividade): void {
    if (this.temErpIntegrado && this.locaisEstoque.length > 0) {
      this.produtoService
        .getProdutoLocalEstoque(produtoid, this.locaisEstoque[0].Id)
        .subscribe((res: Produto) => {
          item.PrecoUnitario = res.PrecoUnitario
        })
    }
    else
    {
      this.produtoService.getProduto(produtoid).subscribe((res:Produto) => {
        item.PrecoUnitario = res.PrecoUnitario;
      });
    }
  }

  changeOrdem(event): void {
    this.ordenarItens()
  }
  getUnidadeMedida(produtoId): string {
    if (this.dicionarioProdutos && this.dicionarioProdutos[produtoId])
      return this.dicionarioProdutos[produtoId].UnidadeMedida
    else return 'UN'
  }
  custo(item: ItensAtividade): number {
    if (!item.ProdutoId) return null

    return item.PrecoUnitario * item.Dose
  }

  //#region fetch produtos
  timoutFectch: any
  private fetchMore(termoBusca?: String) {
    if (this.timoutFectch) clearTimeout(this.timoutFectch)

    this.timoutFectch = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca
      this.carregandoProduto = true
      this.produtoService
        .getProdutosPaginandoSemCultivar(
          this.planejamentoId,
          this.pagina,
          this.termoBusca,
          false,
          false,
        )
        .subscribe((ret) => {
          this.carregandoProduto = false

          if (!this.ultimaPagina || termoBusca != null) {
            const ids = this.produtos.map((c) => c.Id)
            const itensToAdd = ret.Lista.filter((c) => !ids.includes(c.Id))
            this.produtos =
              termoBusca != null ? ret.Lista : this.produtos.concat(itensToAdd)
          }

          this.ultimaPagina = ret.TotalPaginas <= this.pagina

          if (!this.ultimaPagina || termoBusca != null)
            this.pagina = termoBusca != null ? 1 : this.pagina + 1

          this.produtos.forEach(
            (p) =>
              (p.GrupoProduto = this.getGrupoProduto(
                p.GrupoProdutoId,
              ).Descricao),
          )
          this.adicionarERP()
        })
    }, 300)
  }

  timoutFectchSemente: any
  private fetchMoreSemente(termoBusca?: String) {
    if (this.timoutFectchSemente) clearTimeout(this.timoutFectchSemente)

    this.timoutFectchSemente = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca
      this.carregandoProduto = true

      this.produtoService
        .getProdutosPaginando(
          this.planejamentoId,
          this.pagina,
          this.termoBusca,
          false,
          true,
        )
        .subscribe((ret) => {
          this.carregandoProduto = false

          if (!this.ultimaPagina || termoBusca != null) {
            const ids = this.sementes.map((c) => c.Id)
            const itensToAdd = ret.Lista.filter((c) => !ids.includes(c.Id))
            this.sementes =
              termoBusca != null ? ret.Lista : this.sementes.concat(itensToAdd)
          }

          this.ultimaPagina = ret.TotalPaginas <= this.pagina

          if (!this.ultimaPagina || termoBusca != null)
            this.pagina = termoBusca != null ? 1 : this.pagina + 1

          this.sementes.forEach(
            (p) =>
              (p.GrupoProduto = this.getGrupoProduto(
                p.GrupoProdutoId,
              ).Descricao),
          )
          this.adicionarERP()
        })
    }, 300)
  }

  timoutFectchServico: any
  private fetchMoreServico(termoBusca?: String) {
    if (this.timoutFectchServico) clearTimeout(this.timoutFectchServico)

    this.timoutFectchServico = setTimeout(() => {
      this.termoBusca = termoBusca != null ? termoBusca : this.termoBusca
      this.carregandoProduto = true

      this.produtoService
        .getProdutosPaginando(
          this.planejamentoId,
          this.pagina,
          this.termoBusca,
          true,
          false,
        )
        .subscribe((ret) => {
          this.carregandoProduto = false

          if (!this.ultimaPagina || termoBusca != null) {
            const ids = this.servicos.map((c) => c.Id)
            const itensToAdd = ret.Lista.filter((c) => !ids.includes(c.Id))
            this.servicos =
              termoBusca != null ? ret.Lista : this.servicos.concat(itensToAdd)
          }

          this.ultimaPagina = ret.TotalPaginas <= this.pagina

          if (!this.ultimaPagina || termoBusca != null)
            this.pagina = termoBusca != null ? 1 : this.pagina + 1

          this.servicos.forEach(
            (p) =>
              (p.GrupoProduto = this.getGrupoProduto(
                p.GrupoProdutoId,
              ).Descricao),
          )
          this.adicionarERP()
        })
    }, 300)
  }
  getGrupoProduto(grupoProdutoid): GrupoProduto {
    return new GrupoProduto() // todo: implementar
  }
  montaDicionarioProdutos() {
    this.dicionarioProdutos = this.produtos.reduce((acc, obj) => {
      acc[obj.Id] = obj
      return acc
    }, {})
  }
  adicionarERP(): void {
    // todo: implementar
  }

  onScrollToEndSemente() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return
    }
    this.fetchMoreSemente(null)
  }

  onScrollToEndServico() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return
    }
    this.fetchMoreServico(null)
  }

  onScrollToEndProduto() {
    if (this.carregandoProduto || this.ultimaPagina) {
      return
    }
    this.fetchMore(null)
  }

  //#endregion
}

import { TipoInsumo } from './../../../../classes/auxiliar/AuxEtapa'
import {
  AfterViewChecked,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap'
import { Atividade } from 'src/app/classes/Atividade'
import { AuxEtapa } from 'src/app/classes/auxiliar/AuxEtapa'
import { EstadioFenologico } from 'src/app/classes/EstadioFenologico'
import { Etapa } from 'src/app/classes/Etapa'
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao'
import { ItemEnum } from 'src/app/classes/ItemEnum'
import { ItensAtividade } from 'src/app/classes/ItensAtividade'
import {
  ProcessoPlanejamento,
  ReferenciasDAP,
} from 'src/app/classes/ProcessoPlanejamento'
import { Produto } from 'src/app/classes/Produto'
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component'
import { UtilsService } from 'src/app/utils/utils.service'
import Swal from 'sweetalert2'
import { TabelaInsumosComponent } from './tabela-insumos/tabela-insumos.component'
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms'
import { EtapaService } from 'src/app/services/etapa.service'
import { v4 as uuidv4 } from 'uuid'
import { foreignKeyData } from '@syncfusion/ej2-angular-grids'
import { EstadioFenologicoService } from 'src/app/services/estadio-fenologico.service'
import { Planejamento } from 'src/app/classes/Planejamento'

@Component({
  selector: 'app-forms-etapa',
  templateUrl: './forms-etapa.component.html',
  styleUrls: ['./forms-etapa.component.scss'],
})
export class FormsEtapaComponent implements AfterViewChecked, OnInit {
  @ViewChild('ModalFormsEtapa', { static: false })
  ModalFormsEtapa: UiModalComponent
  @ViewChild('tabset', { static: false })
  tabset!: NgbTabset

  @ViewChild('tableSementes', { static: false })
  tableSementes: TabelaInsumosComponent
  @ViewChild('tableAdubacaoLinha', { static: false })
  tableAdubacaoLinha!: TabelaInsumosComponent
  @ViewChild('tablePulverizacaoSulco', { static: false })
  tablePulverizacaoSulco!: TabelaInsumosComponent
  @ViewChild('tableTratamentoSemente', { static: false })
  tableTratamentoSemente!: TabelaInsumosComponent

  etapa: Etapa
  @Input() gruposOperacao: GrupoOperacao[] = []
  @Input() atividades: Atividade[] = []
  @Input() planejamento: Planejamento
  @Input() processosPlanejamento: ProcessoPlanejamento[]
  @Input() produtos: Produto[]
  @Input() sementes: Produto[]
  @Input() servicos: Produto[]
  @Output() finalizarCriacao: EventEmitter<any> = new EventEmitter()

  titulo: string = 'Nova Etapa'
  tipoInsumo = TipoInsumo
  auxEtapa: AuxEtapa = new AuxEtapa()
  atividadeSelecionada: Atividade = new Atividade()
  isSubmit: boolean = false
  estadiosFenologicos: EstadioFenologico[] = []
  showContent: boolean = false
  expandInfoOperacionais: boolean = true
  expandPlanInsumos: boolean = true
  expandInfoPlantio: boolean = false
  isPlantio: boolean = false
  btnSaveCarregando: boolean = false
  btnExcluirCarregando: boolean = false

  plantasPorHectare: number = 0
  auxAtividades: Atividade[] = []
  planejamentoId: number
  listaReferencia: ItemEnum[] = ReferenciasDAP
  // sementes: Produto[] = [];
  // produtos: Produto[] = [];

  teste: any
  constructor(
    private utils: UtilsService,
    private estadioFenologicoService: EstadioFenologicoService,
    private etapaService: EtapaService,
  ) { }

  ngOnInit(): void {
  }

  listarEstadioFenologico() {
    if (this.estadiosFenologicos === null || this.estadiosFenologicos === undefined || this.estadiosFenologicos.length === 0 || this.estadiosFenologicos.length === 1) {
      this.estadioFenologicoService.getEstadiosFenologicos().subscribe(ret => {
        this.estadiosFenologicos = []
        let estadioFen = new EstadioFenologico();
        estadioFen.Id = -1;
        estadioFen.Descricao = "-";
        this.estadiosFenologicos.push(estadioFen);
        if (ret) {
          ret.forEach(estadio => {
            if (estadio.CulturaId == this.planejamento.CulturaId) {
              this.estadiosFenologicos.push(estadio);
            }
          });
        }
      });
    }
  }

  ngAfterViewChecked() {
    if (this.tableSementes) {
      //console.log('tableSementes carregado:', this.tableSementes);
    }
  }
  openModal(planejamentoId: number): void {
    this.planejamentoId = planejamentoId;
    this.auxEtapa = new AuxEtapa();
    this.auxEtapa.ItensAtividade = [];
    this.auxEtapa.PlanejamentoId = planejamentoId;
    this.listarEstadioFenologico();
    this.showContent = true;
    this.ModalFormsEtapa.show();
  }

  openEditModal(auxEtapa: AuxEtapa): void {
    this.planejamentoId = auxEtapa.PlanejamentoId;
    this.auxEtapa = auxEtapa;
    this.titulo = `Editar Etapa: ${this.auxEtapa.Descricao}`;
    this.listarAtividades();
    this.verificarPlantio();
    this.changeInfoPlantio();
    this.listarEstadioFenologico();
    this.showContent = true;
    this.ModalFormsEtapa.show();
    this.grupoOperacaoChange(true);
  }

  fecharModal(): void {
    this.ModalFormsEtapa.hide();
    this.showContent = false;
    this.isSubmit = false;
  }

  salvarEtapa(): void {
    this.btnSaveCarregando = true
    var form = this.getFormulario()

    if (!form.valid) {
      this.isSubmit = true
      this.btnSaveCarregando = false
      return
    }

    // valida itens da atividade
    var itensInvalidos = false
    this.auxEtapa.ItensAtividade.forEach((p) => {
      if ((!this.auxEtapa.TemAdubacaoLinha && p.AdubacaoLinha) ||
        (!this.auxEtapa.TemPulverizacaoSulco && p.PulverizacaoSulco) ||
        (!this.auxEtapa.TemTratamentoSementes && p.TratamentoSementes)) {
        this.auxEtapa.ItensAtividade = this.auxEtapa.ItensAtividade.filter(x => x != p)
        return;
      }

      var formItem = this.getFormularioItem(p)
      if (!formItem.valid) {
        p.isSubmit = true
      }
      if (p.isSubmit) itensInvalidos = true
    })

    if (itensInvalidos) {
      this.btnSaveCarregando = false
      this.utils.handleWarning(
        'Existem campos não preenchidos em um ou mais itens.',
      )
      return
    }
    if (this.auxEtapa.Id) {
      this.etapaService.editarAuxEtapa(this.auxEtapa).subscribe(
        (res) => {
          this.auxEtapa = res
          this.finalizarCriacao.emit()
          this.utils.handleSuccess('Etapa atualizada com sucesso!')
          this.btnSaveCarregando = false
        },
        (error) => {
          this.utils.getErro(error)
          this.btnSaveCarregando = false
        },
      )
    } else
      this.etapaService.adicionarAuxEtapa(this.auxEtapa).subscribe(
        (res) => {
          this.auxEtapa = res
          this.fecharModal()
          this.finalizarCriacao.emit()
          this.utils.handleSuccess('Etapa criada com sucesso!')
          this.btnSaveCarregando = false
        },
        (error) => {
          this.utils.getErro(error)
          this.btnSaveCarregando = false
        },
      )
  }

  excluirEtapa(): void {
    this.utils
      .getConfirma(
        `Deseja excluir a Etapa: ${this.auxEtapa.Descricao}?`,
        'Se continuar essa ação não poderá ser desfeita',
      )
      .then((res) => {
        if (res) {
          this.btnExcluirCarregando = true
          this.etapaService.deleteEtapa(this.auxEtapa.Id).subscribe(
            (res) => {
              this.finalizarCriacao.emit()
              this.utils.handleSuccess('Etapa excluida com sucesso!')
              this.btnExcluirCarregando = false
              this.fecharModal()
            },
            (error) => {
              this.utils.getErro(error)
              this.btnExcluirCarregando = false
            },
          )
        }
      })
  }

  removerEtapa(item: ItensAtividade): void {
    if (item.Id)
      this.auxEtapa.ItensAtividade = this.auxEtapa.ItensAtividade.filter(
        (p) => p.Id != item.Id,
      )
    else if (item.IdNovoItem)
      this.auxEtapa.ItensAtividade = this.auxEtapa.ItensAtividade.filter(
        (p) => p.IdNovoItem != item.IdNovoItem,
      )
  }
  //#region utils
  toggleInfoOperacionais(): void {
    this.expandInfoOperacionais = !this.expandInfoOperacionais
  }
  togglePlanInsumos(): void {
    this.expandPlanInsumos = !this.expandPlanInsumos
  }
  toggleInfoPlantio(): void {
    this.expandInfoPlantio = !this.expandInfoPlantio
  }
  onAtividadeChange(): void {
    this.atividadeSelecionada = this.atividades.find(
      (a) => a.Id == this.auxEtapa.AtividadeId,
    )
  }
  getFormulario(): FormGroup {
    return new FormGroup({
      grupoOperacao: new FormControl(this.auxEtapa.GrupoOperacaoId, [
        Validators.required,
      ]),
      operacaoAgricola: new FormControl(this.auxEtapa.GrupoOperacaoId, [
        Validators.required,
      ]),
      referencia: new FormControl(this.auxEtapa.ReferenciaDAP, [
        Validators.required,
      ]),
      diasAposPlantio: new FormControl(this.auxEtapa.DiasAposPlantio, [
        Validators.required,
      ]),
      descricao: new FormControl(this.auxEtapa.Descricao, [
        Validators.required,
      ]),
    })
  }

  getFormularioItem(item: ItensAtividade): FormGroup {
    return new FormGroup({
      ordem: new FormControl(item.Ordem, [Validators.required]),
      produto: new FormControl(item.ProdutoId, [Validators.required]),
      dose: new FormControl(item.Dose, [
        Validators.required,
        this.maiorQueZero,
      ]),
    })
  }
  maiorQueZero(control: AbstractControl): ValidationErrors | null {
    return control.value > 0 ? null : { minValue: true }
  }
  clearGrupoOperacao(): void {
    this.auxEtapa.GrupoOperacaoId = null
    this.auxEtapa.ReferenciaDAP = null
    this.auxEtapa.Espacamento = null
    this.auxEtapa.SementeMt = null
    this.auxEtapa.TemAdubacaoLinha = false
    this.auxEtapa.TemPulverizacaoSulco = false
    this.auxEtapa.TemTratamentoSementes = false

    this.auxAtividades = []
      ;[this.isPlantio, this.expandInfoPlantio] = [false, false]
  }
  grupoOperacaoChange(openEditModal: boolean = false): void {
    var gpOp = this.gruposOperacao.find(
      (g) => g.Id == this.auxEtapa.GrupoOperacaoId,
    )
    this.auxEtapa.ProcessoPlanejamentoId = gpOp.ProcessoPlanejamentoId
    if (!this.auxEtapa.ProcessoPlanejamentoId) {
      Swal.fire({
        title: 'Vínculo de Processo necessário',
        text:
          'O Grupo de Operação selecionado não possui um Processo vinculado. Para prosseguir e salvar esta etapa, acesse as configurações do Grupo de Operação e vincule um Processo.',
        type: 'info',
        showCloseButton: true,
        confirmButtonText: 'Fechar',
      }).finally(() => {
        if(!openEditModal){
          this.auxEtapa.GrupoOperacaoId = null
          this.auxEtapa.ReferenciaDAP = null
        }
      })
      return
    }

    if(openEditModal)
      return

    this.listarAtividades()
    this.verificarPlantio(true)
  }

  verificarPlantio(grupoOperacaoChange: boolean = false) {
    var processoPlanejamento = this.processosPlanejamento.find(
      (p) => p.Id == this.auxEtapa.ProcessoPlanejamentoId,
    )
    if (processoPlanejamento) {
      ;[this.isPlantio, this.expandInfoPlantio] = [
        processoPlanejamento.Plantio,
        processoPlanejamento.Plantio,
      ]
      if (grupoOperacaoChange)
        this.auxEtapa.ReferenciaDAP = processoPlanejamento.ReferenciaDAP
    }
  }
  listarAtividades() {
    this.auxAtividades = this.atividades.filter(
      (a) => a.GrupoOperacaoId == this.auxEtapa.GrupoOperacaoId,
    )
  }
  changeInfoPlantio(): void {
    this.plantasPorHectare =
      (10000 / (this.auxEtapa.Espacamento / 100)) * this.auxEtapa.SementeMt
  }
  addItemAtividade(): void {
    var atividade = new ItensAtividade()
    atividade.IdNovoItem = uuidv4() // id temporario para identificar um item sem Id identificador
    const maiorOrdem = this.auxEtapa.ItensAtividade.length ? this.auxEtapa.ItensAtividade.reduce((max, obj) => obj.Ordem > max.Ordem ? obj : max, this.auxEtapa.ItensAtividade[0]) : null;
    atividade.Ordem = maiorOrdem ? maiorOrdem.Ordem + 1 : 1;
    switch (this.tabset.activeId) {
      case 'tab-sementes':
        this.auxEtapa.ItensAtividade.push(atividade)
        this.tableSementes.attItensAtividade(this.auxEtapa.ItensAtividade)
        break
      case 'tab-adubacaoLinha':
        atividade.AdubacaoLinha = true
        this.auxEtapa.ItensAtividade.push(atividade)
        this.tableAdubacaoLinha.attItensAtividade(this.auxEtapa.ItensAtividade)
        break
      case 'tab-PulverizacaoSulco':
        atividade.PulverizacaoSulco = true
        this.auxEtapa.ItensAtividade.push(atividade)
        this.tablePulverizacaoSulco.attItensAtividade(
          this.auxEtapa.ItensAtividade,
        )
        break
      case 'tab-tratamentoSemente':
        atividade.TratamentoSementes = true
        this.auxEtapa.ItensAtividade.push(atividade)
        this.tableTratamentoSemente.attItensAtividade(
          this.auxEtapa.ItensAtividade,
        )
        break
    }
  }

  //#endregion
}

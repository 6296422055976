import { Atividade } from "./Atividade";
import { Etapa } from "./Etapa";
import { ItemEnum } from "./ItemEnum";
import { ItensAtividade } from "./ItensAtividade";

export class AtividadePlanejamento {
    Id: number;
    PlanejamentoId: number;
    AtividadeId: number;
    EstadioFenologicoId: number;
    TipoAplicacaoId: number;
    DiasAposPlantio: number = 0;
    DiasParaAlerta: number = 0;
    EtapaId: number;
    DataPrevista: Date = new Date();
    Duracao: string = "00:00:00";
    DataPrevistaFinal: Date = new Date();
    ProcessoPlanejamentoId : number;
    TemAdubacaoLinha: boolean;
    TemPulverizacaoSulco: boolean;
    TemTratamentoSementes: boolean;
    Espacamento: number = 0;
    SementeMt: number = 0;
    ReferenciaDAP: number = 0;
    getReferenciaDAP:string = "";
    Atividade: Atividade;
    OperacaoAgricolaId:number;
    //Auxiliares
    GrupoOperacaoId: number;


    //
    ItensAtividade: ItensAtividade[];
}

export enum enumProcessoPlanejamento
{
    Correcao_Solo = 1,
    Adubacao_Pre_Plantio = 2,
    Dessecacao_Pre_Plantio = 3,
    Tratamento_Semente = 4,//era 3
    Plantio = 5, // era 4
    Adubacao_Pos_Plantio = 6, // era 5
    Via_Barra = 7, // era 6
    Dessecacao_Pre_Colheita = 8,
    Preparo_De_Solo = 9,
    Colheita = 10
}


export const listaProcessosPlanejamento: ItemEnum[] = [
    {name: 'Correção de Solo', value: enumProcessoPlanejamento.Correcao_Solo},
    {name: 'Adubação Pré-Plantio', value: enumProcessoPlanejamento.Adubacao_Pre_Plantio},
    {name: 'Dessecação Pré-Plantio', value: enumProcessoPlanejamento.Dessecacao_Pre_Plantio},
    {name: 'Tratamento de Sementes', value: enumProcessoPlanejamento.Tratamento_Semente},
    {name: 'Plantio', value: enumProcessoPlanejamento.Plantio},
    {name: 'Adubação Pós-Plantio', value: enumProcessoPlanejamento.Adubacao_Pos_Plantio},
    {name: 'Aplicação de Defensivos', value: enumProcessoPlanejamento.Via_Barra},
    {name: 'Dessecação Pré-Colheita', value: enumProcessoPlanejamento.Dessecacao_Pre_Colheita},
    {name: 'Preparo de Solo', value: enumProcessoPlanejamento.Preparo_De_Solo},
    {name: 'Colheita', value: enumProcessoPlanejamento.Colheita}
];

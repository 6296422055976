import { ItemEnum } from "../ItemEnum";
import { Usuario } from "../Usuario";
import { Anexo } from "./Anexo";
import { Comentario } from "./Comentario";

export class Chamado{
    Id: number = 0;
    Modulo: EnumModuloChamado = EnumModuloChamado.Operacoes;
    Tipo: EnumTipoChamado = EnumTipoChamado.Duvida;
    Descricao: string = "";
    Criticidade: EnumCriticidadeChamado = EnumCriticidadeChamado.Baixo;
    Status: EnumStatusChamado = EnumStatusChamado.Aberto;
    DataAlteracao: string;
    DataCadastro: string;
    UsuarioAlteracaoId: number;
    UsuarioCadastroId: number;

    // Nao mapeados
    UsuarioCadastro: Usuario = new Usuario();
    Anexos: Anexo[] = [];
    Comentarios: Comentario[] = [];
    QtdComentarios: number = 0;
    Excluindo = false;
}

export enum EnumModuloChamado{
    Operacoes = 1,
    Compras,
    Manutencoes
}

export enum EnumTipoChamado{
    Duvida = 1,
    Erro,
    Sugestao
}

export enum EnumCriticidadeChamado{
    Baixo = 1,
    Medio,
    Alto
}

export enum EnumStatusChamado{
    Aberto = 1,
    EmAndamento,
    Concluido
}

export const ListaModuloChamado: ItemEnum[] = [
  {name: 'Compras', value: EnumModuloChamado.Compras},
  {name: 'Manutenções', value: EnumModuloChamado.Manutencoes},
  {name: 'Operações', value: EnumModuloChamado.Operacoes},
];

export const ListaTipoChamado: ItemEnum[] = [
  {name: 'Dúvida', value: EnumTipoChamado.Duvida},
  {name: 'Erro', value: EnumTipoChamado.Erro},
  {name: 'Sugestão', value: EnumTipoChamado.Sugestao},
];

export const ListaCriticidadeChamado: ItemEnum[] = [
  {name: 'Crítico', value: EnumCriticidadeChamado.Alto},
  {name: 'Importante', value: EnumCriticidadeChamado.Medio},
  {name: 'Normal', value: EnumCriticidadeChamado.Baixo},
];

export const ListaStatusChamado: ItemEnum[] = [
  {name: 'Aberto', value: EnumStatusChamado.Aberto},
  {name: 'Em andamento', value: EnumStatusChamado.EmAndamento},
  {name: 'Concluído', value: EnumStatusChamado.Concluido},
];


export class TipoAplicacao {
    Id: number;
    Descricao:string = "";
    TipoBicoId: number;
    Vazao: number = 0;
    DoseTanque: number = 0;

    ExigeTaxa: boolean;
    ExigePercentualIrrigacao: boolean;
    ExigePesoBalde: boolean;
    ExigeEspacamento: boolean;
    VazaoDigitada: boolean;
    ExigeTipoBico: boolean;
    ExigeVazao: boolean;
    ExigeDoseTanque: boolean;
    ExigeCapacidadeTanque:boolean = false;
    EhViaPivo:boolean = false;
    EhAplicacaoSolidos:boolean = false;
    DivisorTempoDeVolta: number = 0;
    ExigeTemperatura: boolean;
    ExigeUmidade: boolean;
    ExigeVelocidadeVento: boolean;
    ExigePhCalda: boolean;
    PadraoTemperatura: number = 0;
    PadraoUmidade: number = 0;
    PadraoVelocidadeVento: number = 0;
    PadraoPhCalda: number = 0;
}

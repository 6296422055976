import { ItensAtividade } from '../ItensAtividade'

export class AuxEtapa {
  Id: number
  PlanejamentoId: number
  GrupoOperacaoId: number
  ProcessoPlanejamentoId: number
  AtividadeId: number
  ReferenciaDAP: number
  DiasAposPlantio: number
  Descricao: string
  EstadioFenologicoId: number
  TemAdubacaoLinha: boolean
  TemPulverizacaoSulco: boolean
  TemTratamentoSementes: boolean
  Espacamento: number
  SementeMt: number

  ItensAtividade: ItensAtividade[]

  //
  GrupoOperacaoDescricao: string;
  OperacaoAgricolaDescricao: string;

}

export enum TipoInsumo {
  Sementes,
  AdubacaoLinha,
  PulverizacaoSulco,
  TratamentoSemente,
}

export class DataGridEtapa {
  EtapaId: number
  DAP: string
  DAPSort: number
  IsFake: boolean
  Etapa: string
  GrupoOperacaoDesc: string
  OperacaoAgricolaDesc: string
  GrupoProdutoDesc: string
  ProdutoDesc: string
  ProdutoId: number;
  Dose: number
  PrecoUnitario: number;
  AuxEtapa: AuxEtapa;
  DesprezarNoCusto: boolean;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Area } from 'src/app/classes/Area';
import { Atividade } from 'src/app/classes/Atividade';
import { Cultura } from 'src/app/classes/Cultura';
import { GrupoOperacao } from 'src/app/classes/GrupoOperacao';
import { EnumStatusOS, EnumTipoOS, OrdemServico } from 'src/app/classes/OrdemServico';
import { Planejamento } from 'src/app/classes/Planejamento';
import { ProcessoPlanejamento } from 'src/app/classes/ProcessoPlanejamento';
import { Safra } from 'src/app/classes/Safra';
import { Usuario, enumPerfil } from 'src/app/classes/Usuario';
import { ApontamentoIndicadoresComponent } from 'src/app/components/ordem-servico/apontamento-indicadores/apontamento-indicadores.component';
import { ApontamentoComponent } from 'src/app/components/ordem-servico/apontamento/apontamento.component';
import { DetalhesOrdemServicoComponent } from 'src/app/components/ordem-servico/ordem-servico/componentes/detalhes-ordem-servico/detalhes-ordem-servico.component';
import { ItemsOrdemServicoComponent } from 'src/app/components/ordem-servico/ordem-servico/componentes/items-ordem-servico/items-ordem-servico.component';
import { ApontamentoService } from 'src/app/services/apontamento.service';
import { AreaService } from 'src/app/services/area.service';
import { AtividadeService } from 'src/app/services/atividade.service';
import { CulturaService } from 'src/app/services/cultura.service';
import { GrupoOperacaoService } from 'src/app/services/grupo-operacao.service';
import { OrdemServicoService } from 'src/app/services/ordem-servico.service';
import { ProcessoPlanejamentoService } from 'src/app/services/processo-planejamento.service';
import { SafraService } from 'src/app/services/safra.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';

@Component({
  selector: 'app-ordem-servico-forms',
  templateUrl: './ordem-servico-forms.component.html',
  styleUrls: ['./ordem-servico-forms.component.scss']
})
export class OrdemServicoFormsComponent implements OnInit {
  @ViewChild('ApontamentoIndicadoresComponent', { static: false }) apontamentoIndicadoresComponent: ApontamentoIndicadoresComponent;
  @ViewChild('Apontamento', { static: false }) apontamentoComponent: ApontamentoComponent;
  @ViewChild('DetalhesOrdemServicoComponent', { static: false }) detalhesOrdemServicoComponent: DetalhesOrdemServicoComponent;

  @ViewChild('ModalConcluir', { static: true }) modalConcluir;

  carregandoModalConcluir: boolean = false;
  isSubmitModalConcluir: boolean = false;

  planejamento: Planejamento = new Planejamento();

  ordemServico: OrdemServico = this.getOs();
  id: number;
  planejamentoId: number;
  inserindo: boolean = true;
  carregando: boolean = false;
  isSubmit: boolean = false;
  liberar: boolean = false;
  areas: Area[];
  safras: Safra[];
  atividades: Atividade[];
  auxAtividades: Atividade[];
  possuiInsumos: boolean = false;
  possuiServicos: boolean = false;
  processosPlanejamento: ProcessoPlanejamento[];
  gruposOperacao: GrupoOperacao[];
  culturas: Cultura[] = [];
  usuarioLogado: Usuario;
  isAdmin: boolean;
  setorId: number
  organizaPorSetor: boolean;

  itemTipo1HasErrors: boolean = false;
  itemTipo2HasErrors: boolean = false;
  itemTipo3HasErrors: boolean = false;
  itemTipo4HasErrors: boolean = false;
  itemTipo5HasErrors: boolean = false;

  @ViewChild('Insumos', { static: false }) itemComponente1: ItemsOrdemServicoComponent;
  @ViewChild('Servicos', { static: false }) itemComponente2: ItemsOrdemServicoComponent;
  @ViewChild('AdubacaoLinha', { static: false }) itemComponente3: ItemsOrdemServicoComponent;
  @ViewChild('PulverizacaoSulco', { static: false }) itemComponente4: ItemsOrdemServicoComponent;
  @ViewChild('TratamentoSementes', { static: false }) itemComponente5: ItemsOrdemServicoComponent;


  constructor(
    public ordensServicoService: OrdemServicoService,
    private atividadeService: AtividadeService,
    private areaService: AreaService,
    private grupoOperacaoService: GrupoOperacaoService,
    private culturaService: CulturaService,
    private safraService: SafraService,
    private storageService: StorageService,
    private utils: UtilsService,
    private router: Router,
    private route: ActivatedRoute,
    private processoPlanejamentoService: ProcessoPlanejamentoService,
    private apontamentoService: ApontamentoService
  ) {
    this.route.params.subscribe(res => {
      if (res.id !== "inserir")
        this.id = res.id;
      this.planejamentoId = res.planejamentoId;
    });
  }

  ngOnInit() {
    // Evento capturado ao liberar os
    this.ordensServicoService.osLiberadaEvento().subscribe(data => {
      this.ordemServico = data;
      this.router.navigate([`ordem-servico/ordem-servico/${data.Id}`]);
    });
    // Evento capturado ao salvar os
    this.ordensServicoService.osSalvaEvento().subscribe(data => {
      this.carregandoModalConcluir = false;
      this.modalConcluir.hide();
      this.ordemServico = data;
      this.router.navigate([`ordem-servico/ordem-servico/${data.Id}`]);
    });
    // Erro ao salvar OS
    this.ordensServicoService.erroEvento().subscribe(err => {
      this.carregandoModalConcluir = false;
      this.modalConcluir.hide();
    })
    // Evento capturado OS Multipla
    this.ordensServicoService.osMultiplaCriadaEvento().subscribe(data => {
      this.limparItens();
      this.router.navigate(['/ordem-servico/ordem-servico'], {
        queryParams: { OSsAvulsaId: data.Ids },
      });
    });

    this.ordensServicoService.OsConcluidaEvento().subscribe(data => {
      this.carregando = false;
    });

    const fazendaId = this.storageService.getFazenda().Id;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.usuarioLogado = this.storageService.getUsuario();
    this.isAdmin = this.usuarioLogado.Perfil == enumPerfil.Admin || this.usuarioLogado.isSuper;
    this.organizaPorSetor = this.storageService.getEmpresa().UsaSetores;

    forkJoin([
      this.processoPlanejamentoService.getProcessosPlanejamento(),
      this.culturaService.getCulturas(),
      this.atividadeService.getAtividadesParaUso(),
      this.grupoOperacaoService.getGruposOperacaoEmUso(),
      this.areaService.getAreasPorFazenda(fazendaId)
    ]).subscribe((resultados) => {
      this.processosPlanejamento = resultados[0];
      this.culturas = resultados[1];
      this.auxAtividades = this.atividades = resultados[2];
      this.gruposOperacao = resultados[3];
      this.areas = resultados[4]

      this.atividades.forEach(a => {
        let grupo = this.gruposOperacao.filter(g => g.Id == a.GrupoOperacaoId);
        a.GrupoOperacaoDescricao = grupo.length > 0 ? grupo[0].Descricao : '';
      });

      if (fazendaId) {
        this.safraService.getSafrasPorAnoFazenda(0, fazendaId).subscribe(
          res => this.safras = res,
          err => this.utils.getErro(err)
        )
      }

    },
      err => {
        this.utils.getErro(err);
      });

    setTimeout(() => {
      if (this.id) {
        this.selecionarOrdemServico();
      }
    }, 300);

    if (this.inserindo) {
      this.ordemServico.Tipo = EnumTipoOS.Avulsa;
      this.ordemServico.Status = EnumStatusOS.Aberta;
      this.ordemServico.FazendaId = this.storageService.getFazenda() ? this.storageService.getFazenda().Id : 0;
    }
  }

  limparItens() {
    if (this.itemComponente1) this.itemComponente1.itensOrdemServico = [];
    if (this.itemComponente2) this.itemComponente2.itensOrdemServico = [];
    if (this.itemComponente3) this.itemComponente3.itensOrdemServico = [];
    if (this.itemComponente4) this.itemComponente4.itensOrdemServico = [];
    if (this.itemComponente5) this.itemComponente5.itensOrdemServico = [];
  }

  getOs(): OrdemServico {
    const os = new OrdemServico();
    os.Status = EnumStatusOS.Aberta;
    os.Tipo = EnumTipoOS.Avulsa;
    //this.ordemServico.FazendaId = this.storageService.getFazenda() ? this.storageService.getFazenda().Id : 0;
    return os;
  }

  changeAtividade() {
    this.limparItens();
    if (!this.ordemServico.AtividadeId) {
      this.possuiInsumos = false;
      this.possuiServicos = false;
    }
    else {
      this.possuiInsumos = this.getAtividade(this.ordemServico.AtividadeId).UtilizaInsumo;
      this.possuiServicos = this.getAtividade(this.ordemServico.AtividadeId).UsaServico;
    }
  }

  getAtividade(id: number): Atividade {
    let nova = new Atividade();
    nova.UtilizaInsumo = true;

    if (!this.atividades)
      return nova;

    let atividades = this.atividades.filter(x =>
      x.Id == id
    );

    return atividades.length > 0 ? atividades[0] : nova;
  }

  itensHasErrors(): boolean {
    const itemErrors = [
      this.itemTipo1HasErrors,
      this.itemTipo2HasErrors,
      this.itemTipo3HasErrors,
      this.itemTipo4HasErrors,
      this.itemTipo5HasErrors
    ];
    return itemErrors.some(error => error);
  }

  save() {
    this.isSubmit = true;
    if (this.ordemServico.AreasMultiplas.length > 0) {
      const itens = (
        (this.itemComponente1 ? this.itemComponente1.itensOrdemServico : [])
          .concat(this.itemComponente2 ? this.itemComponente2.itensOrdemServico : [])
          .concat(this.itemComponente3 ? this.itemComponente3.itensOrdemServico : [])
          .concat(this.itemComponente4 ? this.itemComponente4.itensOrdemServico : [])
          .concat(this.itemComponente5 ? this.itemComponente5.itensOrdemServico : [])
      )
      this.ordensServicoService.save(this.ordemServico, itens);
    }
    else
      this.ordensServicoService.save(this.ordemServico);

    setTimeout(() => {
      if (this.id) {
        this.selecionarOrdemServico();
      }
    }, 1000);
  }

  confirmar() {
    this.liberar = true;
    this.ordensServicoService.confirmarLiberacaoInsumos(this.ordemServico);
    setTimeout(() => {
      if (this.id) {
        this.selecionarOrdemServico();
      }
    }, 300);
  }

  reabrir() {
    this.carregando = true;
    this.ordensServicoService.reabrirOS(this.ordemServico.Id).subscribe(
      ret => {
        this.carregando = false;
        this.ordemServico = new OrdemServico();
        this.ordemServico = ret;
        this.router.navigate([`ordem-servico/ordem-servico/${ret.Id}`]);
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    )
  }

  apontamentoSalvo(obj: any) {
    this.selecionarOrdemServico();
  }

  reativar() {
    this.carregando = true;
    this.ordensServicoService.reativar(this.ordemServico.Id).subscribe(
      ret => {
        this.ordemServico = ret;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    )
  }

  reabrirFechada() {
    this.carregando = true;
    // if(this.ordemServico.Status == EnumStatusOS.Concluida) {
    //   this.apontamentoService.existeApontamentos(this.ordemServico.Id).subscribe(
    //     res => {
    //       if(res) {
    //         this.utils.handleWarningSwal("Atenção", "Exclua todos os apontamentos antes de reabrir a Ordem de Serviço!");
    //         this.carregando = false;
    //       }
    //     },
    //     err => {
    //       this.carregando = false;
    //       this.utils.getErro(err)
    //     }
    //   )
    //   return;
    // }
    this.ordensServicoService.reabrirFechada(this.ordemServico.Id).subscribe(
      ret => {
        this.ordemServico = ret;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    )
  }

  cancelarOs() {
    if (this.ordemServico.Status == EnumStatusOS.ParcialmenteConcluida) {
      this.apontamentoService.existeApontamentos(this.ordemServico.Id).subscribe(
        res => {
          if (res) {
            this.utils.handleWarningSwal("Atenção", "Exclua todos os apontamentos antes de cancelar a Ordem de Serviço!");
          }
        },
        err => this.utils.getErro(err)
      )
      return;
    }

    this.carregando = true;
    this.ordensServicoService.cancelarOs(this.ordemServico.Id).subscribe(
      ret => {
        this.ordemServico = ret;
        this.carregando = false;
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      }
    )
  }

  imprimir() {
    this.ordensServicoService.imprimir(this.ordemServico.Id);
  }

  duplicarOs() {
    this.ordensServicoService.duplicarOs(this.ordemServico.Id).subscribe(
      ret => {
        this.ordemServico = ret.OrdemServico;
        //this.itensOrdemServico = [];
        this.ordensServicoService.emitirEventoOsSalva(this.ordemServico);
        this.router.navigate(['ordem-servico/ordem-servico/' + this.ordemServico.Id]);
      }
    ), err => this.utils.getErro(err)
  }

  concluir() {
    const qtdItens = (
      (this.itemComponente1 ? this.itemComponente1.itensOrdemServico.length : 0) +
      (this.itemComponente2 ? this.itemComponente2.itensOrdemServico.length : 0) +
      (this.itemComponente3 ? this.itemComponente3.itensOrdemServico.length : 0) +
      (this.itemComponente4 ? this.itemComponente4.itensOrdemServico.length : 0) +
      (this.itemComponente5 ? this.itemComponente5.itensOrdemServico.length : 0)
    )
    if (qtdItens == 0) {
      this.modalConcluir.show();
    } else {
      this.carregando = true;

      this.ordensServicoService.concluirOS(this.ordemServico);
    }
  }

  concluirModal() {
    this.carregandoModalConcluir = true;
    if (this.ordemServico.DataRealizadaFinal == null) {
      this.utils.handleWarning('Preencha o campo Data conclusão')
      this.carregandoModalConcluir = false;
      return;
    }
    this.ordensServicoService.concluirOS(this.ordemServico);
  }

  cancelar() {
    this.router.navigate(['/ordem-servico/ordem-servico']);
  }

  fechouModalApontamentos(onInit?: boolean) {
    this.selecionarOrdemServico();
  }

  selecionarOrdemServico() {
    //this.limparItens();
    this.carregando = true;
    this.ordensServicoService.getOrdemServico(this.id).subscribe(res => {
      this.ordemServico = res;
      if (this.ordemServico.Status == EnumStatusOS.Aberta)
        this.inserindo = true;

      this.ordemServico.AreasMultiplas = [];

      this.planejamentoId = this.ordemServico.PlanejamentoId;
      this.changeAtividade();

      forkJoin([
        this.areaService.getArea(res.AreaId),
        this.safraService.getSafrasPorAnoFazenda(0, res.FazendaId),
        this.areaService.getAreasPorFazenda(res.FazendaId)
      ]).subscribe(
        resultados => {
          this.setorId = resultados[0].SetorId;
          this.safras = resultados[1];
          this.areas = resultados[2];
          this.carregando = false;
        },
        err => this.utils.getErro(err)
      )

    }, ex => {
      this.utils.getErro(ex);
      this.carregando = false;
    })
  }

  novoApontamentoIndicadores() {
    console.log(this.apontamentoIndicadoresComponent == null)
    this.apontamentoIndicadoresComponent.novoApontamentoIndicadores();
  }

  novoApontamento() {
    this.carregando = true;
    this.apontamentoComponent.novoApontamento();
  }

  atualizaAreaItens() {
    this.detalhesOrdemServicoComponent.validarTamanhoArea();
  }

  pararCarregamento(){
    this.carregando = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Cultivar } from 'src/app/classes/Cultivar';
import { Cultura } from 'src/app/classes/Cultura';
import { EstadioFenologico } from 'src/app/classes/EstadioFenologico';
import { CultivarService } from 'src/app/services/cultivar.service';
import { CulturaService } from 'src/app/services/cultura.service';
import { EstadioFenologicoService } from 'src/app/services/estadio-fenologico.service';
import { StorageService } from 'src/app/services/storage.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cultivar',
  templateUrl: './cultivar.component.html',
  styleUrls: ['./cultivar.component.scss']
})
export class CultivarComponent implements OnInit {

  cultivares: Cultivar[];
  auxCultivares: Cultivar[];
  cultivar: Cultivar;
  id:number;
  inserindo: boolean = false;
  carregando: boolean = false;

  form: any;
  public isSubmit: boolean;
  textoBusca: string = "";
  perfilLogado: number = 0;

  culturas: Cultura[];
  cultivaresPadrao: Cultivar[];
  cultivaresPorCultura: Cultivar[];
  estadiosFenologicos: EstadioFenologico[];

  FEITO: string = "Feito!";
  ERRO: string = "Erro!";
  CANCELAR: string = "Cancelar";
  SIM: string = "Sim";
  CONFIRMAR_DELETE: string = "Se deletar, esta ação não poderá ser desfeita!";
  DELETAR_PARAM: string;
  VARIEDADE_DELETADA: string = "Variedade deletada!";
  VARIEDADE_CADASTRADA: string = "Variedade cadastrada!";
  VARIEDADE_ALTERADA: string = "Variedade alterada!";

  constructor(private cultivaresService: CultivarService,
              private utils: UtilsService,
              private culturaService: CulturaService,
              private estadioFenologicoService: EstadioFenologicoService,
              private route: ActivatedRoute,
              private router: Router,
              private storageService: StorageService,
              private translate: TranslateService) {
    this.route.params.subscribe(res => {
      this.id = res.id;
    });
  }

  ngOnInit() {
    this.perfilLogado = this.storageService.getUsuario().Perfil;
    this.inserindo = this.router.url.search("inserir") > 0;
    this.cultivar = new Cultivar();

    this.culturaService.getCulturas().subscribe(ret =>{
      this.culturas = ret;
    }, err => this.utils.getErro(err));

    this.cultivaresService.getCultivaresPadrao().subscribe(ret =>{
      this.cultivaresPadrao = ret;
      if(this.id > 0)
        this.selecionarCultivar();
      else
        this.listarCultivares();
    }, err => this.utils.getErro(err));

    this.estadioFenologicoService.getEstadiosFenologicos().subscribe(ret =>{
      this.estadiosFenologicos = ret;
    }, err => this.utils.getErro(err));

  }

  selecionarCultivar() {
    this.ERRO = this.translate.instant("ERRO");

    this.carregando = true;
    this.cultivaresService.getCultivar(this.id).subscribe(res =>{
      this.cultivar = new Cultivar();
      this.cultivar = res;
      this.changeCultura();
      this.carregando = false;
    },
    err =>{
      this.utils.getErro(err);
      this.carregando = false;
    })
  }

  listarCultivares(){
    this.ERRO = this.translate.instant("ERRO");
    this.carregando = true;
    this.cultivaresService.getCultivares().subscribe(
      res => {
        this.cultivares = res;
        this.auxCultivares = res;
        this.carregando = false;

        if (this.textoBusca != '')
          this.filtrarCultivares();
      },
      err => {
        this.utils.getErro(err);
        this.carregando = false;
      });
  }

  save(form: any) {
    if (!form.valid) {
      this.isSubmit = true;
      return;
    }
    this.carregando = true;
    this.ERRO = this.translate.instant("ERRO");
    this.FEITO = this.translate.instant("FEITO");
    this.VARIEDADE_CADASTRADA = this.translate.instant("VARIEDADE_CADASTRADA");
    this.VARIEDADE_ALTERADA = this.translate.instant("VARIEDADE_ALTERADA");

    if(this.cultivar.Id > 0)
      this.cultivaresService.putCultivar(this.cultivar.Id, this.cultivar).subscribe(
        res => {
          this.utils.handleSuccess(this.VARIEDADE_ALTERADA);
          this.carregando = false;
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });
    else
      this.cultivaresService.postCultivar(this.cultivar).subscribe(
        res => {
          this.utils.handleSuccess(this.VARIEDADE_CADASTRADA);
          this.carregando = false;
          this.cancelar();
        },
        err => {
          this.utils.getErro(err);
          this.carregando = false;
        });

  }

  excluir(grupooperacaoId: number, nome: string) {
    this.ERRO = this.translate.instant("ERRO");
    this.FEITO = this.translate.instant("FEITO");
    this.CONFIRMAR_DELETE = this.translate.instant("CONFIRMAR_DELETE");
    this.CANCELAR = this.translate.instant("CANCELAR");
    this.SIM = this.translate.instant("SIM");
    this.VARIEDADE_DELETADA = this.translate.instant("VARIEDADE_DELETADA");
    this.DELETAR_PARAM = this.translate.instant("DELETAR_PARAM", {value: nome});

    Swal.fire({
      title: this.DELETAR_PARAM,
      text: this.CONFIRMAR_DELETE,
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this.CANCELAR,
      confirmButtonText: this.SIM,
    }).then((willDelete) => {
        if (willDelete.dismiss) {

        } else {
          this.carregando = true;
          this.cultivaresService.deleteCultivar(grupooperacaoId).subscribe(
            res => {
              this.utils.handleSuccess(this.VARIEDADE_DELETADA);
              this.listarCultivares();
              this.carregando = false;
            },
            err => {
              this.utils.getErro(err);
              this.carregando = false;
            });
        }
      });
  }

  filtrarCultivares(){
    this.cultivares = this.auxCultivares.filter(x =>
      x.Descricao.toUpperCase().includes(this.textoBusca.toUpperCase()) ||
      x.Id.toString().includes(this.textoBusca.toUpperCase())
      );
  }

  cancelar(){
    this.router.navigate(['/cadastros/variedade']);
  }

  getCultura(id: number) : Cultura {
    if (!this.culturas)
      return null;

    let cultura = this.culturas.filter(x =>
      x.Id==id
    );

    return cultura.length > 0 ? cultura[0] : new Cultura();
  }

  getEstadioFenologico(id: number) : EstadioFenologico {
    if (!this.estadiosFenologicos)
      return null;

    let estadio = this.estadiosFenologicos.filter(x =>
      x.Id==id
    );

    return estadio.length > 0 ? estadio[0] : new EstadioFenologico();
  }

  getEstadiosPorCultura(culturaId: number) : EstadioFenologico[] {
    if (!this.estadiosFenologicos)
      return null;

    return this.estadiosFenologicos.filter(x =>
      x.CulturaId==culturaId
    );
  }

  getCultivaresPorCultura(culturaId: number) : Cultivar[] {
    console.log(this.cultivaresPadrao);
    if (!this.cultivaresPadrao)
      return null;

    let r = this.cultivaresPadrao.filter(x =>
      x.CulturaId==culturaId
    );
    console.log(r);
    return r;
  }

  changeCultura(){
    this.cultivaresPorCultura = this.getCultivaresPorCultura(this.cultivar.CulturaId);
    let tipo = this.cultivaresPadrao.find(c=>c.Id === this.cultivar.CultivarBaseId);
    if(tipo === null || tipo === undefined)
      this.cultivar.CultivarBaseId = null;
    if(tipo.CulturaId !== this.cultivar.CulturaId)
      this.cultivar.CultivarBaseId = null;
  }

  changeCultivarPadrao(){
    if (this.inserindo) {
      let cultivarPadrao: Cultivar[] = this.cultivaresPadrao.filter(x => x.Id == this.cultivar.CultivarBaseId);

      if (cultivarPadrao.length > 0){
        this.cultivar.Descricao = cultivarPadrao[0].Descricao;
        this.cultivar.Obtentor = cultivarPadrao[0].Obtentor;
        this.cultivar.PesoMilSemente = cultivarPadrao[0].PesoMilSemente;
        this.cultivar.Germinacao = cultivarPadrao[0].Germinacao;
        this.cultivar.Vigor = cultivarPadrao[0].Vigor;
      }
    }
  }

  getDescricaoTipo(cultivarId: number): string{
    if(!this.cultivaresPadrao)
      return "";
    else{
      let cultivar =  this.cultivaresPadrao.find(x => x.Id == cultivarId);
      if(cultivar)
        return cultivar.Descricao;
      else
        return "";
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IpApiService } from './services/ip-api.service';
import { StorageService } from './services/storage.service';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import ptBRLocale from '../assets/locale-syncfuse/pt-BR.json';

//loadCldr(numberingSystems, numbers, timeZoneNames, weekData);
setCulture('pt-BR');
L10n.load(ptBRLocale);
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
    private translate: TranslateService,
    private ipApiService: IpApiService,
    private storageService: StorageService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.setLanguage();
  }

  setLanguage() {
    let usuario = this.storageService.getUsuario()
    if(usuario && usuario.Idioma){
      this.translate.setDefaultLang(usuario.Idioma);
      this.translate.use(usuario.Idioma);
    }
    else{
      this.translate.setDefaultLang('pt');
      this.ipApiService.getIPInfo().subscribe(res =>{
        if (res.country_code.toUpperCase() == 'BR') {
          this.translate.setDefaultLang('pt');
          this.translate.use('pt');
        }
        else {
          this.translate.setDefaultLang('es');
          this.translate.use('es');
        };
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Chamado } from '../classes/chamado/Chamado';
import { Anexo } from '../classes/chamado/Anexo';
import { Comentario } from '../classes/chamado/Comentario';

@Injectable({
  providedIn: 'root'
})
export class ChamadoService {

  constructor(private http: HttpClient) { }

  // Chamado
  get(): Observable<any> {
    return this.http.get(`${environment.apiURL}/chamado`)
  }

  find(id: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/chamado/${id}`)
  }
  filtrar(modulo: number, tipo: number, status: number, criticidade: number, dataInicio: Date, dataFim: Date, empresa: number): Observable<any> {
    let url = `${environment.apiURL}/chamado/filtrar?dataInicio=${dataInicio}&dataFim=${dataFim}`;
    if (modulo) url = `${url}&modulo=${modulo}`;
    if (tipo) url = `${url}&tipo=${tipo}`;
    if (status) url = `${url}&status=${status}`;
    if (criticidade) url = `${url}&criticidade=${criticidade}`;
    if (empresa) url = `${url}&empresaId=${empresa}`;
    return this.http.get(url)
  }

  post(chamado: Chamado): Observable<any> {
    return this.http.post(`${environment.apiURL}/chamado`, chamado)
  }

  put(id: number, chamado: Chamado): Observable<any> {
    return this.http.put(`${environment.apiURL}/chamado/${id}`, chamado)
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/chamado/${id}`)
  }

  // Anexo
  getAnexos(chamadoId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/chamado/${chamadoId}/anexo`)
  }

  postAnexo(chamadoId: number, anexo: Anexo): Observable<any> {
    return this.http.post(`${environment.apiURL}/chamado/${chamadoId}/anexo`, anexo)
  }

  putAnexo(chamadoId: number, id: number, anexo: Anexo): Observable<any> {
    return this.http.put(`${environment.apiURL}/chamado/${chamadoId}/anexo/${id}`, anexo)
  }

  deleteAnexo(chamadoId: number, id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/chamado/${chamadoId}/anexo/${id}`)
  }

  // Comentario
  getComentarios(chamadoId: number): Observable<any> {
    return this.http.get(`${environment.apiURL}/chamado/${chamadoId}/comentario`)
  }

  postComentario(chamadoId: number, comentario: Comentario): Observable<any> {
    return this.http.post(`${environment.apiURL}/chamado/${chamadoId}/comentario`, comentario)
  }

  putComentario(chamadoId: number, id: number, comentario: Comentario): Observable<any> {
    return this.http.put(`${environment.apiURL}/chamado/${chamadoId}/comentario/${id}`, comentario)
  }

  deleteComentario(chamadoId: number, id: number): Observable<any> {
    return this.http.delete(`${environment.apiURL}/chamado/${chamadoId}/comentario/${id}`)
  }
}

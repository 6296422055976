import { Produto } from './Produto';

export class ItensAtividade {
    Id: number;
    ProdutoId: number;
    AtividadePlanejamentoId: number;
    Dose: number = 0;
    DesprezarNoCusto: boolean;
    PrecoUnitario: number = 0;
    ItensAtividadePlanoMestre: ItensAtividade;
    Especificacao:string = "";
    PulverizacaoSulco: boolean;
    AdubacaoLinha: boolean;
    TratamentoSementes: boolean;
    Ordem: number = 0;
    IsServico: boolean;

    Produto: Produto;
    //Não Mapeados
    UnidadeMedida:string = "";
    NomeGrupoProduto: string;
    NomeProduto: string;
    IdNovoItem: string;
    fakeItem: boolean;
    isSubmit: boolean = false;

}

import {ArchwizardModule} from 'ng2-archwizard/dist';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { CustomFormsModule } from 'ng2-validation';
import { RouterModule } from '@angular/router';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgNumberFormatterModule } from 'ng-number-formatter';
import { NgbDatepickerModule, NgbCollapseModule, NgbDropdownModule, NgbTooltipModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagInputModule } from 'ngx-chips';
import { SafraComponent } from './safra/safra.component';
import { PlanoMestreProducaoComponent } from './plano-mestre-producao/plano-mestre-producao.component';
import { TabLateralComponent } from './plano-mestre-producao/tab-lateral/tab-lateral.component';
import { ListaComponent } from './plano-mestre-producao/lista/lista.component';
import { PlanoMestreProducaoDetalheComponent } from './plano-mestre-producao/detalhe/detalhe.component';
import { ListaDetalheComponent } from './plano-mestre-producao/lista-detalhe/lista-detalhe.component';
import { SafraDetalheComponent } from './safra/safra-detalhe/safra-detalhe.component';
import { SafraTabLateralComponent } from './safra/safra-tab-lateral/safra-tab-lateral.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '../cadastros/cadastros.module';
import { PlanejamentoProducaoComponent } from './planejamento-producao/planejamento-producao.component';
import { ApontamentosProducaoComponent } from './planejamento-producao/apontamentos-producao/apontamentos-producao.component';
import { PlanejamentoProducaoAsideFilterComponent } from './planejamento-producao/planejamento-producao-aside-filter/planejamento-producao-aside-filter.component';
import { ItensPlanejamentoSafraComponent } from './planejamento-producao/itens-planejamento-safra/itens-planejamento-safra.component';
import { SharedFarmModule } from '../shared-farm/shared-farm.module';
import { ExibirAnexosComponent } from './planejamento-producao/exibir-anexos/exibir-anexos.component';
import { AreaConfigModalComponent } from './safra/safra-tab-lateral/area-config-modal/area-config-modal.component';
import { FormsEtapaComponent } from './plano-mestre-producao/forms-etapa/forms-etapa.component';
import { TabelaInsumosComponent } from './plano-mestre-producao/forms-etapa/tabela-insumos/tabela-insumos.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    SafraComponent,
    PlanoMestreProducaoComponent,
    TabLateralComponent,
    ListaComponent,
    PlanoMestreProducaoDetalheComponent,
    ListaDetalheComponent,
    SafraDetalheComponent,
    SafraTabLateralComponent,
    PlanejamentoProducaoComponent,
    ApontamentosProducaoComponent,
    PlanejamentoProducaoAsideFilterComponent,
    ItensPlanejamentoSafraComponent,
    ExibirAnexosComponent,
    AreaConfigModalComponent,
    FormsEtapaComponent,
    TabelaInsumosComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    CustomFormsModule,
    RouterModule,
    CurrencyMaskModule,
    TextMaskModule,
    NgNumberFormatterModule,
    NgbDatepickerModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbTabsetModule,
    TagInputModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    AutocompleteLibModule,
    ArchwizardModule,
    SharedFarmModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'pt'
    })
  ],
  exports:
  [
    SafraComponent
  ]
})
export class PlanejamentoModule { }
